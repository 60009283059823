import React, { useState, useEffect } from 'react';
import Spline from '@splinetool/react-spline';

export default function SplineComponent3() {
 
    
        return (
          <Spline scene="https://prod.spline.design/nXCy9nNOHhXYvLGV/scene.splinecode" />



        );
      }
      

      