import React, { useState, useEffect } from 'react';
import Spline from '@splinetool/react-spline';

export default function SplineComponent1() {
 
    
        return (
          <Spline scene="https://prod.spline.design/bA7dFTuBJxSSEzd4/scene.splinecode" />



        );
      }
      